import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { ArrowRight } from '@icons/ArrowRight';

interface Props {
  contentPosition: string;
  hasArrowRight?: boolean;
  isHiddenMobile?: boolean;
  isHiddenDesktop?: boolean;
  isDark?: boolean;
  isRemovedMetaData?: boolean;
}

export const ListItem: FC<PropsWithChildren & Props> = ({
  children,
  isHiddenMobile,
  isHiddenDesktop,
  contentPosition,
  hasArrowRight,
  isDark = false,
}) => {
  const className = clsx(isHiddenMobile && 'hidden md:flex', isHiddenDesktop && 'flex md:hidden');

  return (
    <div
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
      className={className}
    >
      {hasArrowRight && (
        <div className="transform rotate-180 origin-center mx-2.5 flex items-start md:transform-none mt-1">
          <ArrowRight $isDark={isDark} />
        </div>
      )}
      {children}
      <meta itemProp="position" content={contentPosition} />
    </div>
  );
};
