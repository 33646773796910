import { ListItem } from './components/ListItem';
import { Link } from './components/Link';
import clsx from 'clsx';

export interface IBreadcrumb {
  id?: string;
  displayName: string;
  slug: string;
}

export interface IBreadcrumbTitles {
  [key: string]: {
    value: string;
  };
}

export const Breadcrumbs = ({
  categories = [],
  titles = {},
  pageName,
  isDark = false,
  isPositionRelative = false,
  page = '',
}: {
  categories?: IBreadcrumb[];
  titles?: IBreadcrumbTitles;
  pageName: string;
  isDark?: boolean;
  isPositionRelative?: boolean;
  page?: string;
  locale?: string;
}) => {
  const url = page ? `/${page}` : '';
  const className = clsx(
    'z-45 w-full bg-none md:pt-6 pt-22px pl-20px flex justify-start items-center md:px-small md:flex-wrap md:items-start md:content-start md:h-14 md:flex md:mb-22px',
    isDark ? 'bg-breadcrumbs-dark text-white' : 'bg-breadcrumbs-light',
    isPositionRelative ? 'relative' : 'absolute',
  );
  return (
    <div
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      data-print="hide"
      className={className}
    >
      <ListItem isHiddenMobile contentPosition="1" isDark={isDark}>
        <Link title={titles?.homeText?.value || 'Home'} href="/" isDark={isDark} />
      </ListItem>
      {categories?.map((category, index) => (
        <ListItem
          key={category.id ?? `breadcrumbs-${index}`}
          isHiddenMobile
          contentPosition={`${index + 2}`}
          hasArrowRight
          isDark={isDark}
        >
          <Link title={category?.displayName} href={`${url}${category?.slug}`} isDark={isDark} />
        </ListItem>
      ))}
      <ListItem
        contentPosition={`${categories.length + 2}`}
        hasArrowRight
        isDark={isDark}
        isHiddenMobile
      >
        <Link title={pageName} isDark={isDark} isLastItem />
      </ListItem>
      {categories.length ? (
        <ListItem
          contentPosition={`${categories.length + 2}`}
          hasArrowRight
          isDark={isDark}
          isHiddenDesktop
        >
          <Link
            title={categories[categories.length - 1].displayName}
            href={`${url}${categories[categories.length - 1]?.slug}`}
            isDark={isDark}
            isLastItem
          />
        </ListItem>
      ) : (
        <ListItem
          contentPosition={`${categories.length + 2}`}
          hasArrowRight
          isDark={isDark}
          isHiddenDesktop
        >
          <Link title={titles?.backText?.value || 'Back'} href="/" isDark={isDark} isLastItem />
        </ListItem>
      )}
    </div>
  );
};
