import { NextLink } from 'components/NextLink';
import { FC } from 'react';
import { Text } from 'components/Typography';
import clsx from 'clsx';

interface Props {
  title: string;
  href?: string;
  isDark?: boolean;
  isLastItem?: boolean;
}

export const Link: FC<Props> = ({ isDark = false, title, href, isLastItem }: Props) => {
  const textClassNames = clsx('whitespace-no-wrap', isLastItem && 'font-medium mb-4');
  const linkClassNames = clsx(
    "hover:after:w-[98%] hover:after:left-[1%] focus:after:w-[98%] focus:after:left-[1%] relative after:content-[''] after:absolute after:block after:w-[0%] after:transition-all after:duration-[0.5s] after:border-b-white after:border-b after:border-solid after:left-2/4 after:-bottom-px",
    isDark ? 'text-white after:border-b-white' : 'text-darkgray after:border-b-black',
    !href ? 'after:hidden cursor-default' : 'cursor-pointer',
  );

  const BreadcrumbTitle = () => (
    <div className={linkClassNames} itemProp="item">
      <Text tag="div" type="leadxs" itemProp="name" className={textClassNames}>
        {title}
      </Text>
    </div>
  );

  return href ? (
    <NextLink href={href}>
      <BreadcrumbTitle />
    </NextLink>
  ) : (
    <BreadcrumbTitle />
  );
};
